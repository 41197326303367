import React from 'react'
import { RegulationRequests } from 'submodules/nerit-aquatec-sdk/services/regulation/RegulationRequests'
import { AutocompletePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/autocomplete-picker/AutocompletePickerCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'

interface ICPProps {
    fieldRegulationCode?: number
    isRequired?: boolean
    onChange?: (locationCode: number) => void
    isMany?: boolean
    fieldName?: string
    formStateManager?: IFormStateManager<any>
    disable?: boolean
    auxFieldNameSelectedOption?: string
}

export function SelectFieldRegulationCP(props: ICPProps): JSX.Element {

    return (
        <AutocompletePickerCP
            label={'Norma'}
            requestConfigGetter={() => RegulationRequests.getNameList()}
            showOptionsOnLoad={true}
            required={props.isRequired}
            isMultiple={props.isMany}
            value={props.fieldRegulationCode}
            onChange={(option: any) => {
                if (!!option && !!props.formStateManager && !!props.auxFieldNameSelectedOption)
                    props.formStateManager.changeFieldValue(props.auxFieldNameSelectedOption, option.label)
                if (!!props.onChange)
                    props.onChange(option)
            }}
            filterOption={true}
            returnFullOption={true}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
            disabled={props.disable}
            allowClear={true}
        />
    )
}
