import React, { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ButtonSizeTP } from 'submodules/nerit-framework-ui/common/components/button/inner/ButtonSizeTP'
import { ModalFieldCP } from 'modules/fields/components/modals/modal-field/ModalFieldCP'
import { FieldResponseDTO } from 'submodules/nerit-aquatec-sdk/services/fields/dtos/responses/FieldResponseDTO'
import { PermissionEnum } from 'submodules/nerit-aquatec-sdk/services/auth/authenticate/enums/PermissionEnum'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'

interface ICPProps {
    field?: FieldResponseDTO
    buttonSize?: ButtonSizeTP
    onSave: () => void
}

export function ButtonModalFieldCP(props: ICPProps): JSX.Element {

    const [isModalAddFieldVisible, setModalAddFieldVisible] = useState<boolean>(false)

    return (
        <AccessControlCP permission={PermissionEnum.ROLE_ADMIN}>
            <ButtonCP
                style={{marginLeft: 10}}
                onClick={() => setModalAddFieldVisible(true)}
                icon={props.field ? 'edit' : 'plus'}
                size={props.buttonSize}
            >
                { props.field ? '' : 'Novo parâmetro' }
            </ButtonCP>

            <ModalFieldCP
                visible={isModalAddFieldVisible}
                onCancel={() => setModalAddFieldVisible(false)}
                field={props.field}
                onSave={() => {
                    setModalAddFieldVisible(false)
                    props.onSave()
                }}
            />
        </AccessControlCP>
    )
}
