import React from 'react'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { RedirectCP } from 'submodules/nerit-framework-ui/common/router/components/redirect/RedirectCP'
import { PermissionEnum } from 'submodules/nerit-aquatec-sdk/services/auth/authenticate/enums/PermissionEnum'
import { PermissionUtils } from 'app/components/access-control/inner/PermissionUtils'
import { PrivateRouter } from 'app/routers/private/PrivateRouter'

interface ICPProps {
	children: React.ReactNode
	permission: PermissionEnum
	redirect?: {
		shouldRedirect: boolean
		redirectTo?: string
	}
}

/**
 * Controle de Acesso.
 */
export function AccessControlCP(props: ICPProps): JSX.Element {

	const isPermitted = PermissionUtils.canAccess(props.permission)

	if (!isPermitted) {
		if (props.redirect?.shouldRedirect) {
			NotificationHelper.warning('Ops!', 'Você não tem permissão para acessar essa página')
			return <RedirectCP path={props.redirect.redirectTo ?? PrivateRouter.HOME} />
		}

		return <></>
	}

	return <>{props.children}</>
}
