import React, { useState } from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ModalSamplingCP } from 'modules/samplings/components/modal-sampling/ModalSamplingCP'
import { SamplingResponseDTO } from 'submodules/nerit-aquatec-sdk/services/samplings/dtos/responses/SamplingResponseDTO'
import { PermissionEnum } from 'submodules/nerit-aquatec-sdk/services/auth/authenticate/enums/PermissionEnum'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'

interface ICPProps {
    sampling?: SamplingResponseDTO
    onSave: () => void
}

export function ButtonModalSamplingCP(props: ICPProps): JSX.Element {

    const [isSamplingModalVisible, setIsSamplingModalVisible] = useState<boolean>(false)

    return (
        <AccessControlCP permission={PermissionEnum.ROLE_ADMIN}>
            <ButtonCP
                onClick={() => setIsSamplingModalVisible(true)}
                icon={!!props.sampling ? 'edit' : 'plus'}
                size={!!props.sampling ? 'small' : 'default'}
            >
                { !props.sampling && 'Nova Campanha' }
            </ButtonCP>

            <ModalSamplingCP
                visible={isSamplingModalVisible}
                onCancel={() => setIsSamplingModalVisible(false)}
                sampling={props.sampling}
                onSave={() => {
                    setIsSamplingModalVisible(false)
                    props.onSave()
                }}
            />
        </AccessControlCP>
    )
}
