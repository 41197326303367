import { IsNotEmpty } from 'submodules/nerit-framework-utils/validators'
import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsOptional } from 'class-validator'

export class LocationFormModel extends FormModel {

    @IsNotEmpty()
    name: string

    @IsOptional()
    description?: string

    @IsNotEmpty()
    lat: string

    @IsNotEmpty()
    long: string

    @IsOptional()
    height?: string

    constructor(initialData?: Partial<LocationFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
