import React from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { AppConfig } from 'config/AppConfig'
import { LoadingOverlayCP } from 'submodules/nerit-framework-ui/common/components/loading/overlay/LoadingOverlayCP'
import styled from 'styled-components'
import { MapPinTP } from 'modules/map/components/google-map/inner/MapPinTP'

interface ICPProps {
    markers: MapPinTP[]
    title?: string
}

export function GoogleMapCP(props: ICPProps): JSX.Element {

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: AppConfig.getInstance().googleMapApiKey,
    })

    const onLoad = React.useCallback(function callback(map) {

        const bounds = new window.google.maps.LatLngBounds();

        // Expande os limites do mapa para incluir todos os marcadores
        props.markers.forEach(marker => bounds.extend(marker.position));
        map.fitBounds(bounds); // Ajusta o zoom automaticamente

    }, [])

    if (!isLoaded)
        return <LoadingOverlayCP show={true}/>

    return (
        <WrapperSCP>
            {
                !!props.title &&
                <TitleWrapper>
                    {props.title}
                </TitleWrapper>
            }
            <GoogleMap
                mapContainerStyle={{ width: '100%', height: '100%' }}
                zoom={10}
                onLoad={onLoad}
                options={{ mapTypeId: "satellite" }}
            >
                {props.markers.map((marker) => (
                    <Marker
                        key={marker.id}
                        position={marker.position}
                        title={marker.title} // Exibe título ao passar o mouse
                        icon={marker.icon} // Define o ícone do marcador
                        label={{
                            text: marker.title,
                            className: 'nrt-map-marker',
                        }}
                        onClick={marker.onClick}
                    />
                ))}
            </GoogleMap>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    height: 100%;
`

const TitleWrapper = styled.div`
  position: absolute;
  top: 63px;
  z-index: 999999;
  left: 12px;
  font-size: 24px;
  font-weight: bold;
  background: white;
  border-radius: 2px;
  padding: 2px 5px;
`
