import React, { useState } from 'react'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { TableLocationsCP } from 'modules/locations/components/table-locations/TableLocationsCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ButtonModalLocationCP } from 'modules/locations/components/buttons/button-modal-location/ButtonModalLocationCP'
import { FiltersLocationsCP } from 'modules/locations/components/filters/filters-locations/FiltersLocationsCP'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { LocationsFiltersFormModel } from 'modules/locations/components/filters/filters-locations/inner/LocationsFiltersFormModel'

export function ScreenLocations(): JSX.Element {

    const [formModel] = useState<LocationsFiltersFormModel>(new LocationsFiltersFormModel())
    const formStateManager = useFormStateManager(formModel)

    const [loadData, setLoadData] = useState<number>(1)

    return (
        <LayoutCP
            header={
                <HeaderCP title={'Pontos'}>
                    <ButtonModalLocationCP
                        onSave={() => setLoadData(TableUtils.getReloadNumber())}
                    />
                </HeaderCP>
            }
            overflowVertical={true}
            content={
                <>
                    <FiltersLocationsCP
                        formStateManager={formStateManager}
                        onFilter={() => setLoadData(TableUtils.getReloadNumber)}
                    />
                    <TableLocationsCP
                        filters={LocationsFiltersFormModel.formatDto(formStateManager)}
                        shouldLoadData={loadData}
                    />
                </>
            }
        />
    )
}
