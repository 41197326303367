import styled from 'submodules/nerit-framework-ui/theme/_old/styledWithTheme'
import React, { useState } from 'react'
import { CardCP } from 'submodules/nerit-framework-ui/common/components/card/CardCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { SamplingImportInstructionsCP } from 'modules/samplings/components/sampling-import-instructions/SamplingImportInstructionsCP'
import { TableSamplingImport } from 'modules/samplings/components/table-sampling-import/TableSamplingImport'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ModalSamplingImportCP } from 'modules/samplings/components/modal-sampling-import/ModalSamplingImportCP'
import { PermissionEnum } from 'submodules/nerit-aquatec-sdk/services/auth/authenticate/enums/PermissionEnum'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'

/**
 */
export function ScreenSamplingImport(): JSX.Element {

    const [reloadTable, setReloadTable] = useState<number>(TableUtils.getReloadNumber())
    const [isImportModalVisible, setIsImportModalVisible] = useState<boolean>(false)

    function onUploadSuccess(): void {
        setReloadTable(TableUtils.getReloadNumber())
        setIsImportModalVisible(false)
    }

    return (
        <>
            <HeaderCP title={'Importação de Amostras'}>
                <AccessControlCP permission={PermissionEnum.ROLE_ADMIN}>
                    <ButtonCP onClick={() => setIsImportModalVisible(true)}>
                        <TextCP text={'Importar'} strong={true}/>
                    </ButtonCP>
                </AccessControlCP>
            </HeaderCP>

            <ContentSCP>
                <CardWrapperSCP>
                    <SamplingImportInstructionsCP/>
                </CardWrapperSCP>

                <CardWrapperSCP>
                    <CardCP title={'Histórico de Importações'}>
                        <TableSamplingImport
                            reloadList={reloadTable}
                        />
                    </CardCP>
                </CardWrapperSCP>
            </ContentSCP>

            <ModalSamplingImportCP
                visible={isImportModalVisible}
                onSave={onUploadSuccess}
                onCancel={() => setIsImportModalVisible(false)}
            />
        </>
    )
}

const ContentSCP = styled.div`
    display: flex;
    overflow-x: auto;
`

const CardWrapperSCP = styled.div`
    width: 50%;
`
