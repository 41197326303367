import React from 'react'
import { GenericChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/GenericChartResponseDTO'
import { HighChartsUtils } from 'modules/reports/components/charts/card-chart/inner/HighChartsUtils'
import { CardHighChartCP } from 'modules/reports/components/charts/card-chart/CardHighChartCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'

interface ICPProps {
    data: GenericChartResponseDTO
    filters?: {
        locationName: string
        regulationName?: string
    }
    appearance?: {
        hideTitle?: boolean
        width?: string
    }
}

/**
 */
export function ChartHistoricalSeriesCP(props: ICPProps): JSX.Element {

    const series: any[] = []
    const uniqueXPlots = new Set<number>()

    props.data.series.forEach((serie) => {
        series.push({
            name: serie.xAxisLabel,
            data: serie.dataArray.map((data) => {

                // Quebra a data retornada, 2022-01-01, nos -
                const dateParts = data.x.split('-')
                // Monta a data UTC em cada parte quebrada. O mes subtrai 1 pois o mes comeca do 0
                const dateUtc = Date.UTC(+dateParts[0], (+dateParts[1]) - 1)

                uniqueXPlots.add(dateUtc)

                return [dateUtc, +data.y]
            })
        })
    })

    const minData: any[] = []
    const maxData: any[] = []

    uniqueXPlots.forEach((dateUtc) => {

        if (!!props.data.regulationLimit?.minPermitted)
            minData.push([dateUtc, +props.data.regulationLimit.minPermitted])

        if (!!props.data.regulationLimit?.maxPermitted)
            maxData.push([dateUtc, +props.data.regulationLimit.maxPermitted])

    })

    if (minData.length > 0) {
        series.push({
            name: `Valor mínimo permitido (${props.filters?.regulationName})`,
            data: minData,
            color: ThemeProject.error,
            type: 'line',
            dashStyle: 'ShortDash',
            marker: {
                enabled: false
            }
        })
    }

    if (maxData.length > 0) {
        series.push({
            name: `Valor máximo permitido (${props.filters?.regulationName})`,
            data: maxData,
            color: ThemeProject.error,
            type: 'line',
            dashStyle: 'ShortDash',
            marker: {
                enabled: false
            }
        })
    }

    return (
        <CardHighChartCP
            width={props.appearance?.width}
            hideTitle={props.appearance?.hideTitle}
            chartOptions={{
                chart: HighChartsUtils.getChart('scatter'),
                series: series,
                title: !!props.filters
                    ? HighChartsUtils.getTitle(`Série Histórica do ${props.data.series.map((serie) => serie.xAxisLabel).join(', ')} para o ${props.filters.locationName}`)
                    : undefined,
                xAxis: {
                    type: 'datetime',
                    tickInterval: 30 * 24 * 3600 * 1000, // Intervalo de 1 mês (em milissegundos)
                    gridLineWidth: 1,
                    labels: {
                        rotation: -45,
                        format: '{value:%b/%Y}' // Formato: mês abreviado em português / ano completo
                    },
                },
                yAxis: {
                    title: {
                        text: props.data.series.length === 1 ? props.data.series[0].xAxisLabel : ''
                    },
                },
                exporting: {
                    enabled: true,
                }
            }}
            chartType={'scatter'}
        />
    )
}
