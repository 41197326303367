import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'
import { IsOptional } from 'class-validator'
import { FieldTypeEnum } from 'submodules/nerit-aquatec-sdk/services/fields/types/FieldTypeEnum'
import { FieldElementTypeEnum } from 'submodules/nerit-aquatec-sdk/services/fields/types/FieldElementTypeEnum'

export class FieldFormModel extends FormModel {

    @IsRequired()
    name: string

    @IsRequired()
    type: FieldTypeEnum

    @IsRequired()
    elementType: FieldElementTypeEnum

    @IsOptional()
    unit: string

    @IsOptional()
    minPermitted: number

    @IsOptional()
    maxPermitted: number

    constructor(initialData?: Partial<FieldFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
