import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import React from 'react'
import { DataAnalysisListItemResponseDTO } from 'submodules/nerit-aquatec-sdk/services/data-analysis/dtos/response/DataAnalysisListItemResponseDTO'
import { ColumnProps } from 'antd/lib/table'
import { NatureEnum } from 'submodules/nerit-aquatec-sdk/common/enums/NatureEnum'
import styled from 'styled-components'
import { FieldValueResponseDTO } from 'submodules/nerit-aquatec-sdk/services/data-analysis/dtos/response/FieldValueResponseDTO'
import { MaskUtils } from 'submodules/nerit-framework-ui/common/utils/MaskUtils'

type TableStructureTypeTP = { columns: ColumnProps<DataAnalysisListItemResponseDTO>[], data: any[] }

/**
 */
export class TableDataAnalysisUtils {

    /**
     */
    static mountTableStructure(
        dataAnalysData: DataAnalysisListItemResponseDTO[],
        onClick: (data: FieldValueResponseDTO) => void,
    ): TableStructureTypeTP {

        const tableColumns: ColumnProps<DataAnalysisListItemResponseDTO>[] = [
            { title: 'Parâmetro', dataIndex: 'field', className: 'fixedColumn col1', width: 150 },
            { title: 'Natureza', dataIndex: 'nature', className: 'fixedColumn col2', width: 150 },
            { title: 'Limite', dataIndex: 'limit', className: 'fixedColumn col3', width: 100 },
        ]

        const currentDataTable: any = []
        dataAnalysData.forEach((record, index) => {

            const limitStr = [record.field.minValid, record.field.maxValid].join(', ')

            const currentRecord: any = {
                key: index,
                field: `${record.field.field.name} (${record.field.unity})`,
                nature: NatureEnum[record.field.nature],
                limit: limitStr !== ',' ? limitStr : undefined,
            }

            record.locations.forEach((locationValue, indexLoc) => {

                const foundLocationColumn = tableColumns.find((td) => td.key === locationValue.location.code)
                if (!foundLocationColumn) {
                    tableColumns.push({
                        title: locationValue.location.name,
                        key: locationValue.location.code,
                        className: `aqt-class-col-${indexLoc % 2}`,
                        children: locationValue.values.map((item) => {
                            return {
                                title: DateUtils.formatDate(item.creationDate, DateFormatEnum.BR_WITHOUT_TIME),
                                dataIndex: TableDataAnalysisUtils.getLocationValueDataIndex(locationValue.location.code, item.creationDate),
                                className: `aqt-class-col-${indexLoc % 2}`,
                                key: item.creationDate,
                                render(text) {
                                    let isOutsideInterval = false
                                    if (!!text && !!record.field.minValid && Number(text) < record.field.minValid)
                                        isOutsideInterval = true
                                    if (!!text && !!record.field.maxValid && Number(text) > record.field.maxValid)
                                        isOutsideInterval = true

                                    let fieldVal: FieldValueResponseDTO | undefined
                                    try {
                                        fieldVal = JSON.parse(text)
                                    } catch (e) {}

                                    return {
                                        children:
                                            <ValueSCP
                                                className={isOutsideInterval ? 'aqt-error-value' : ''}
                                                onClick={() => {
                                                    if (!!fieldVal)
                                                        onClick(fieldVal)
                                                }}
                                            >
                                                { MaskUtils.applyNumberMask(Number(fieldVal?.compiledValue), 5) }
                                            </ValueSCP>
                                    }
                                }
                            }
                        }),
                    })
                }

                locationValue.values.forEach((itemValue) => {
                    const dataIndex = TableDataAnalysisUtils.getLocationValueDataIndex(locationValue.location.code, itemValue.creationDate)
                    currentRecord[dataIndex] = JSON.stringify(itemValue)
                })
            })

            currentDataTable.push(currentRecord)
        })

        return {
            data: currentDataTable,
            columns: tableColumns
        }
    }

    static getLocationValueDataIndex(locationCode: number, creationDate: string): string {
        return `${locationCode}${creationDate}`
    }

}

const ValueSCP = styled.div`
    cursor: pointer;
`
