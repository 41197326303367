import React from 'react'
import { StatisticChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/StatisticChartResponseDTO'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { ThemeProject } from 'config/theme/project/ThemeProject'
import { CardHighChartCP } from 'modules/reports/components/charts/card-chart/CardHighChartCP'
import { HighChartsUtils } from 'modules/reports/components/charts/card-chart/inner/HighChartsUtils'

interface ICPProps {
    data: StatisticChartResponseDTO
    filters: {
        locationName: string
        regulationName?: string
    }
}

/**
 */
export function ChartStatisticCP(props: ICPProps): JSX.Element {

    const series: any = [{
        name: props.data.statisticSerie.xAxisLabel,
        data: props.data.statisticSerie.dataArray.map((data) => +data.y),
        dataLabels: {
            enabled: true,
            inside: false, // Coloca os rótulos fora da barra (acima)
            formatter: function () {
                return (this as any).y
                    .toFixed(2) // Limita a 2 casas decimais
                    .replace('.', ','); // Substitui o ponto por vírgula
            }
        },
    }]

    if (!!props.data.regulationLimit?.minPermitted) {
        series.push(HighChartsUtils.getMinOrMaxPermittedSerie('min', props.data.statisticSerie.dataArray.length, props.data.regulationLimit?.minPermitted, props.filters.regulationName))
    }

    if (!!props.data.regulationLimit?.maxPermitted) {
        series.push(HighChartsUtils.getMinOrMaxPermittedSerie('max', props.data.statisticSerie.dataArray.length, props.data.regulationLimit?.maxPermitted, props.filters.regulationName))
    }

    return (
        <RowCP>
            <ColumnCP md={18}>
                <CardHighChartCP
                    buttonsToHide={['linearScale', 'chartType']}
                    chartOptions={{
                        chart: HighChartsUtils.getChart('column'),
                        series: series,
                        xAxis: {
                            categories: props.data.statisticSerie.dataArray.map((data) => data.x),
                            gridLineWidth: 1
                        },
                        title: HighChartsUtils.getTitle(`Análise estatística do ${props.data.fieldSerie.xAxisLabel} no ${props.filters.locationName}`),
                        yAxis: {
                            min: 0,
                            title: {
                                text: props.data.statisticSerie.yAxisLabel
                            },
                        },
                        tooltip: {
                            formatter: function () {
                                // Personalize o formato do número aqui
                                return `<b>${this.x}</b><br/>${this.series.name}: ${Number(this.y).toFixed(3)}`
                            }
                        },
                        exporting: {
                            enabled: true,
                        }
                    }}
                    chartType={'column'}
                />
            </ColumnCP>
            <ColumnCP md={6}>
                <CardHighChartCP
                    buttonsToHide={['linearScale', 'chartType', 'fixedRatioSize']}
                    chartOptions={{
                        chart: HighChartsUtils.getChart('pie'),
                        title: HighChartsUtils.getTitle('Percentual de resultados em acordo e desacordo com a norma de referência'),
                        series: [{
                            colorByPoint: true,
                            data: [
                                {
                                    name: 'conforme',
                                    y: props.data.accordanceData?.totalInAccordanceWithRegulation ?? 0,
                                    color: ThemeProject.success,
                                },
                                {
                                    name: 'não conforme',
                                    y: props.data.accordanceData?.totalInNoAccordanceWithRegulation ?? 0,
                                    color: ThemeProject.error
                                },
                            ]
                        } as any],
                        tooltip: {
                            formatter: function () {
                                return `<b>${this.point.name}</b>: ${this.y}`;
                            }
                        },
                        plotOptions: {
                            pie: {
                                allowPointSelect: true,
                                cursor: 'pointer',
                                dataLabels: {
                                    enabled: true, // Ativa os rótulos
                                    format: '{point.percentage:.0f}%',
                                    distance: -50,
                                    style: {
                                        color: '#FFFFFF', // Cor branca
                                        fontSize: '24px', // Tamanho da fonte
                                        fontWeight: 'bold', // Negrito
                                        textOutline: '0px contrast' // Remove o contorno padrão dos rótulos
                                    },
                                },
                                showInLegend: true
                            }
                        },
                        exporting: {
                            enabled: true,
                        }
                    }}
                    chartType={'pie'}
                />
            </ColumnCP>
        </RowCP>
    )
}
