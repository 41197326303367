import React from 'react'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FieldElementTypeEnum, FieldElementTypeLabelEnum } from 'submodules/nerit-aquatec-sdk/services/fields/types/FieldElementTypeEnum'

interface ICPProps {
    value?: FieldElementTypeEnum
    isRequired?: boolean
    onChange?: (val: FieldElementTypeEnum) => void
    width?: number
    fieldName?: string
    formStateManager?: IFormStateManager<any>
}

export function SelectFieldElementTypeCP(props: ICPProps): JSX.Element {

    return (
        <SelectCP
            label={'Tipo do Elemento'}
            required={!!props.isRequired}
            options={Object.values(FieldElementTypeEnum).map((val) => ({
                value: val,
                label: FieldElementTypeLabelEnum[val]
            }))}
            onChange={props.onChange}
            width={props.width}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
        />
    )
}

