export enum FieldTypeEnum {
    NUMERIC = 'NUMERIC',
    TEXT = 'TEXT',
}

export enum FieldTypeLabelEnum {
    NUMERIC = 'Númerico',
    TEXT = 'Texto',
}

