import React, { useEffect, useState } from 'react'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { BasicModalCPProps, ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { LocationFormModel } from 'modules/locations/components/modal-location/inner/LocationFormModel'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { LocationResponseDTO } from 'submodules/nerit-aquatec-sdk/services/location/dtos/responses/LocationResponseDTO'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { LocationSaveRequestDTO } from 'submodules/nerit-aquatec-sdk/services/location/dtos/requests/LocationSaveRequestDTO'
import { LocationRequests } from 'submodules/nerit-aquatec-sdk/services/location/LocationRequests'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'

interface ICPProps extends BasicModalCPProps {
    location?: LocationResponseDTO
}

/**
 */
export function ModalLocationCP(props: ICPProps): JSX.Element {

    const [formModal, setFormModel] = useState<LocationFormModel>(new LocationFormModel())
    const formStateManager = useFormStateManager(formModal)

    const saveRequest = useRequest('none')
    useEffect(onSaveRequestChange, [saveRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     */
    function init(): void {

        setFormModel(new LocationFormModel())
        if (!props.visible || !props.location)
            return

        setFormModel(new LocationFormModel({
            name: props.location.name,
            lat: props.location.latitude,
            long: props.location.longitude,
            height: props.location.altitude,
            description: props.location.description
        }))
    }

    /**
     */
    async function save(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!

        const dto: LocationSaveRequestDTO = {
            name: formValues.name,
            latitude: formValues.lat,
            altitude: formValues.height,
            longitude: formValues.long,
            description: formValues.description,
        }

        if (!props.location)
            saveRequest.runRequest(LocationRequests.create(dto))
        else
            saveRequest.runRequest(LocationRequests.update(props.location.code, dto))
    }

    /**
     */
    function onSaveRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE, true))
            return

        props.onSave()
    }

    return (
        <ModalCP
            title={'Dados do ponto'}
            visible={props.visible}
            onOk={save}
            onCancel={props.onCancel}
            actionLoading={saveRequest.isAwaiting}
        >
            <RowCP>
                <ColumnCP size={6}>
                    <InputCP
                        label={'Nome'}
                        required={true}
                        formStateManager={formStateManager}
                        fieldName={'name'}
                    />
                </ColumnCP>
                <ColumnCP size={18}>
                    <InputCP
                        label={'Descrição'}
                        formStateManager={formStateManager}
                        fieldName={'description'}
                    />
                </ColumnCP>
            </RowCP>
            <RowCP>
                <ColumnCP size={8}>
                    <InputCP
                        label={'Latitude'}
                        required={true}
                        formStateManager={formStateManager}
                        fieldName={'lat'}
                    />
                </ColumnCP>
                <ColumnCP size={8}>
                    <InputCP
                        label={'Longitude'}
                        required={true}
                        formStateManager={formStateManager}
                        fieldName={'long'}
                    />
                </ColumnCP>
                <ColumnCP size={8}>
                    <InputCP
                        label={'Altitude'}
                        formStateManager={formStateManager}
                        fieldName={'height'}
                    />
                </ColumnCP>
            </RowCP>
        </ModalCP>
    )
}
