import React, { useState } from 'react'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { ButtonModalFieldCP } from 'modules/fields/components/buttons/button-modal-field/ButtonModalFieldCP'
import { TableFieldsCP } from 'modules/fields/components/tables/table-fields/TableFieldsCP'
import { FieldFiltersFormModel } from 'modules/fields/components/filters/filters-fields/inner/FieldFiltersFormModel'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FiltersFieldsCP } from 'modules/fields/components/filters/filters-fields/FiltersFieldsCP'

export function ScreenFields(): JSX.Element {

    const [formModel] = useState<FieldFiltersFormModel>(new FieldFiltersFormModel())
    const formStateManager = useFormStateManager(formModel)

    const [shouldLoadData, setShouldLoadData] = useState<number>(TableUtils.getReloadNumber())

    return (
        <LayoutCP
            header={
                <HeaderCP title={'Biblioteca de Parâmetros'}>
                    <ButtonGroupCP mode={'separeted'}>
                        <ButtonModalFieldCP
                            onSave={() => setShouldLoadData(TableUtils.getReloadNumber())}
                        />
                    </ButtonGroupCP>
                </HeaderCP>
            }
            overflowVertical={true}
            content={
                <>
                    <FiltersFieldsCP
                        formStateManager={formStateManager}
                        onFilter={() => setShouldLoadData(TableUtils.getReloadNumber)}
                    />

                    <TableFieldsCP
                        filters={FieldFiltersFormModel.formatDto(formStateManager)}
                        shouldLoadData={shouldLoadData}
                    />
                </>
            }
        />
    )
}
