import { AquatecSystemApiEnum } from 'submodules/nerit-aquatec-sdk/common/AquatecSystemApiEnum'
import { HttpMethodEnum } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/enums/HttpMethodEnum'
import { RequestConfigTP } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/types/RequestConfigTP'
import { FieldSaveRequestDTO } from 'submodules/nerit-aquatec-sdk/services/fields/dtos/requests/FieldSaveRequestDTO'
import { FieldSearchRequestDTO } from 'submodules/nerit-aquatec-sdk/services/fields/dtos/requests/FieldSearchRequestDTO'
import { FieldResponseDTO } from 'submodules/nerit-aquatec-sdk/services/fields/dtos/responses/FieldResponseDTO'

export class FieldRequests {

    public static CONTROLLER_ROOT = 'fields'
	public static SEARCH_EP = 'search'
	public static SAVE_EP = 'save'
	public static UPDATE_EP = 'update/:code'
    public static DELETE_EP = 'delete/:code'

	static search = (dto: FieldSearchRequestDTO): RequestConfigTP<FieldResponseDTO> => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${FieldRequests.CONTROLLER_ROOT}/${FieldRequests.SEARCH_EP}`,
        method: HttpMethodEnum.GET,
        params: dto,
    })

	static create = (dto: FieldSaveRequestDTO): RequestConfigTP => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${FieldRequests.CONTROLLER_ROOT}/${FieldRequests.SAVE_EP}`,
        method: HttpMethodEnum.POST,
        params: dto
    })

	static update = (code: number, dto: FieldSaveRequestDTO): RequestConfigTP => ({
		baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
		url: `${FieldRequests.CONTROLLER_ROOT}/${FieldRequests.UPDATE_EP.replace(':code', code.toString())}`,
		method: HttpMethodEnum.PUT,
		params: dto,
	})

	static delete = (code: number): RequestConfigTP => ({
        baseUrlType: AquatecSystemApiEnum.AQUATEC_API,
        url: `${FieldRequests.CONTROLLER_ROOT}/${FieldRequests.DELETE_EP.replace(':code', code.toString())}`,
        method: HttpMethodEnum.DELETE,
    })

}
