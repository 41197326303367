import React from 'react'
import styled from 'styled-components'
import { UserRequests } from 'submodules/nerit-aquatec-sdk/services/users/UserRequests'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { PermissionEnum } from 'submodules/nerit-aquatec-sdk/services/auth/authenticate/enums/PermissionEnum'

interface ICPProps {
    shouldLoadData: number
    onResetPassword: (code: number) => void
    onDeactivate: (code: number) => void
}

export function TableUsersCP(props: ICPProps): JSX.Element {

    return (
        <WrapperSCP>
            <TableFromApiCP<any>
                apiConfig={{
                    hasPagination: true,
                    pageSize: 20,
                    requestConfigTP: () => UserRequests.listUsers()
                }}
                appearance={{
                    showReloadButton: true,
                    wrappedOnCard: true,
                    showTotalOnHeader: true
                }}
                shouldLoadData={props.shouldLoadData}
                columns={[
                    {
                        title: '#',
                        render: (text, record) => record.code
                    },
                    {
                        title: 'Nome',
                        render: (text, record) => record.name
                    },
                    {
                        title: 'E-mail',
                        render: (text, record) => record.email
                    },
                    {
                        title: 'Status',
                        render: (text, record) => (record.status ? 'ATIVO' : 'INATIVO')
                    },
                    {
                        title: 'Permissão',
                        render: (text, record) => record.role
                    },
                    {
                        render: (text, record) => (record.status &&
                            <AccessControlCP permission={PermissionEnum.ROLE_ADMIN}>
                                <ButtonGroupCP mode={'separeted'}>
                                    <ButtonCP
                                        onClick={() => props.onResetPassword(record.code)}
                                        confirmMsg={'Tem certeza que deseja resetar a senha?'}
                                        size={'small'}
                                    >
                                        Resetar Senha
                                    </ButtonCP>
                                    <ButtonCP
                                        onClick={() => props.onDeactivate(record.code)}
                                        confirmMsg={'Tem certeza que deseja desativar o usuário?'}
                                        size={'small'}
                                        type={'danger'}
                                    >
                                        Desativar
                                    </ButtonCP>
                                </ButtonGroupCP>
                            </AccessControlCP>
                        )
                    }
                ]}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
`
