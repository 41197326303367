import React from 'react'
import 'react-photo-view/dist/react-photo-view.css'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { ModalLocationCP } from 'modules/locations/components/modal-location/ModalLocationCP'
import { ModalSamplingCP } from 'modules/samplings/components/modal-sampling/ModalSamplingCP'
import { useLocation } from 'react-router'
import { ScreenContentDevTestScreenLinks } from 'app/screens/screen-dev/content/ScreenContentDevTestScreenLinks'
import { LoadingOverlayLogoMovimentCP } from 'app/components/loading-overlay-logo-moviment/LoadingOverlayLogoMovimentCP'
import { GoogleMapCP } from 'modules/map/components/google-map/GoogleMapCP'

/**
 * Pagina para testar componentes de forma rapida
 *
 * Chamar na ROTA: /dev-test
 */
export function ScreenDevTest(): JSX.Element {

    const routeLocation = useLocation()
    const params = new URLSearchParams(routeLocation.search)

    function doNothing(): void {
        NotificationHelper.info('Do Nothing')
    }

    if (!!params.get('screenLinks'))
        return <ScreenContentDevTestScreenLinks/>

    return (
        <>
            <ModalLocationCP visible={false} onCancel={doNothing} onSave={doNothing}/>
            <ModalSamplingCP visible={false} onCancel={doNothing} onSave={doNothing}/>

            <LoadingOverlayLogoMovimentCP/>

            <GoogleMapCP markers={[
                {
                    id: 1,
                    position: {lat: -3.745, lng: -38.523},
                    title: "Local 1",
                    // icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" // Ícone vermelho,
                    icon: `${process.env.PUBLIC_URL}/images/map/red-dot.png`
                },
                {
                    id: 2,
                    position: {lat: -3.750, lng: -38.530},
                    title: "Local 2",
                    icon: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png" // Ícone azul
                }
            ]}/>
        </>
    )
}
