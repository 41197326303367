import React, { useEffect, useState } from 'react'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { UserRequests } from 'submodules/nerit-aquatec-sdk/services/users/UserRequests'
import { SelectPermissionCP } from 'modules/users/components/select-permission/SelectPermissionCP'
import { PermissionEnum } from 'submodules/nerit-aquatec-sdk/services/auth/authenticate/enums/PermissionEnum'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { UserRequestDTO } from 'submodules/nerit-aquatec-sdk/services/users/dtos/requests/UserResponseDTO'

interface IAddFieldClassificationModalCPProps {
    visible: boolean
    onCancel: () => void
    onSave: () => void
}

/**
 */
export function ModalUserCP(props: IAddFieldClassificationModalCPProps): JSX.Element {

    const [name, setName] = useState<string>()
    const [email, setEmail] = useState<string>()
    const [role, setRole] = useState<PermissionEnum>()

    const saveRequest = useRequest<void>('none')
    useEffect(onSaveRequestChange, [saveRequest.isAwaiting])

    async function save(): Promise<void> {

        if (!name || !email || !role) {
            NotificationHelper.error('Ops', 'Preencha os campos obrigatórios')
            return
        }

        const dto: UserRequestDTO = {
            name,
            email,
            role
        }
        saveRequest.runRequest(UserRequests.createUser(dto))
    }

    /**
     */
    function onSaveRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE, true))
            return

        props.onSave()
    }

    return (
        <ModalCP
            visible={props.visible}
            actionLoading={saveRequest.isAwaiting}
            onCancel={props.onCancel}
            onOk={save}
            title={'Novo usuário'}
            width={600}
        >

            <InputCP
                label={'Nome'}
                value={name}
                onChange={(gp) => setName(gp)}
                required={true}
            />

            <InputCP
                label={'E-mail'}
                value={email}
                onChange={(gp) => setEmail(gp)}
                required={true}
            />

            <SelectPermissionCP
                value={role}
                onChange={setRole}
                isRequired={true}
            />
        </ModalCP>
    )
}
