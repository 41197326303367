import React, { useEffect, useState } from 'react'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { ButtonDeleteRecordCP } from 'submodules/nerit-framework-ui/common/components/table/columns/ButtonDeleteRecordCP'
import { FieldRequests } from 'submodules/nerit-aquatec-sdk/services/fields/FieldRequests'
import { FieldResponseDTO } from 'submodules/nerit-aquatec-sdk/services/fields/dtos/responses/FieldResponseDTO'
import { FieldTypeLabelEnum } from 'submodules/nerit-aquatec-sdk/services/fields/types/FieldTypeEnum'
import { FieldElementTypeLabelEnum } from 'submodules/nerit-aquatec-sdk/services/fields/types/FieldElementTypeEnum'
import { ButtonModalFieldCP } from 'modules/fields/components/buttons/button-modal-field/ButtonModalFieldCP'
import { PermissionEnum } from 'submodules/nerit-aquatec-sdk/services/auth/authenticate/enums/PermissionEnum'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { FieldSearchRequestDTO } from 'submodules/nerit-aquatec-sdk/services/fields/dtos/requests/FieldSearchRequestDTO'

interface ICPProps {
    shouldLoadData: number
    filters: FieldSearchRequestDTO
}

export function TableFieldsCP(props: ICPProps): JSX.Element {

    const [shouldLoadData, setShouldLoadData] = useState<number>(props.shouldLoadData)
    useEffect(() => setShouldLoadData(props.shouldLoadData), [props.shouldLoadData])

    return (
        <TableFromApiCP<FieldResponseDTO>
            apiConfig={{
                hasPagination: true,
                requestConfigTP: (filtersDto) => FieldRequests.search({
                    ...filtersDto, ...props.filters,
                })
            }}
            appearance={{
                showReloadButton: true,
                wrappedOnCard: true,
                showTotalOnHeader: true,
            }}
            shouldLoadData={shouldLoadData}
            columns={[
                {
                    title: '#',
                    render: (text, record) => record.code
                },
                {
                    title: 'Nome',
                    render: (text, record) => record.name
                },
                {
                    title: 'Unidade',
                    render: (text, record) => record.unity
                },
                {
                    title: 'Tipo',
                    render: (text, record) => FieldTypeLabelEnum[record.type]
                },
                {
                    title: 'Tipo do Elemento',
                    render: (text, record) => FieldElementTypeLabelEnum[record.elementType]
                },
                {
                    title: 'Mínimo Permitido',
                    render: (text, record) => record.minValid
                },
                {
                    title: 'Máximo Permitido',
                    render: (text, record) => record.maxValid
                },
                {
                    render: (text, record) =>
                        <AccessControlCP permission={PermissionEnum.ROLE_ADMIN}>
                            <ButtonGroupCP mode={'separeted'}>
                                <ButtonModalFieldCP
                                    onSave={() => setShouldLoadData(TableUtils.getReloadNumber())}
                                    field={record}
                                    buttonSize={'small'}
                                />
                                <ButtonDeleteRecordCP
                                    requestConfigTP={() => FieldRequests.delete(record.code)}
                                    onDelete={() => setShouldLoadData(TableUtils.getReloadNumber())}
                                />
                            </ButtonGroupCP>
                        </AccessControlCP>
                }
            ]}
        />
    )
}
