import React, { useEffect, useState } from 'react'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { DataAnalysisFiltersFormModel } from 'modules/locations/components/filters-data-analisys/inner/DataAnalysisFiltersFormModel'
import { DataAnalysisRequests } from 'submodules/nerit-aquatec-sdk/services/data-analysis/DataAnalysisRequests'
import { DataAnalysisSearchRequestDTO } from 'submodules/nerit-aquatec-sdk/services/data-analysis/dtos/request/DataAnalysisSearchRequestDTO'
import { TableCP } from 'submodules/nerit-framework-ui/common/components/table/TableCP'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { DataAnalysisListItemResponseDTO } from 'submodules/nerit-aquatec-sdk/services/data-analysis/dtos/response/DataAnalysisListItemResponseDTO'
import { TableDataAnalysisUtils } from 'modules/locations/components/table-data-analysis/inner/TableDataAnalysisUtils'
import { ColumnProps } from 'antd/lib/table'
import styled from 'styled-components'
import { ButtonExportFromApiCP } from 'submodules-third-party/components/button-export-from-api/ButtonExportFromApiCP'
import { FieldValueResponseDTO } from 'submodules/nerit-aquatec-sdk/services/data-analysis/dtos/response/FieldValueResponseDTO'
import { ModalDataMeasurementCP } from 'modules/locations/components/modals/modal-data-measurement/ModalDataMeasurementCP'

interface ICPProps {
    reloadData: number
    formStateManager: IFormStateManager<DataAnalysisFiltersFormModel>
    onLoading: (isLoading: boolean) => void
}

/**
 */
export function TableDataAnalisysCP(props: ICPProps): JSX.Element {

    const [tableData, setTableData] = useState<any>()
    const [tableColumns, setTableColumns] = useState<ColumnProps<DataAnalysisListItemResponseDTO>[]>()

    const [isModalMeasurementDataVisible, setIsModalMeasurementDataVisible] = useState<boolean>(false)
    const [measurementDataToEdit, setMeasurementDataToEdit] = useState<FieldValueResponseDTO>()

    const [dataAnalysisSearchRequestDTO, setDataAnalysisSearchRequestDTO] = useState<DataAnalysisSearchRequestDTO>()
    const getDataAnalisysRequest = useRequest<ListResponseDTO<DataAnalysisListItemResponseDTO>>()
    useEffect(onGetDataAnalysisRequestChange, [getDataAnalisysRequest.isAwaiting])
    useEffect(() => props.onLoading(getDataAnalisysRequest.isAwaiting), [getDataAnalisysRequest.isAwaiting])

    useEffect(() => { doSearch() }, [props.reloadData])

    /**
     */
    async function doSearch(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(props.formStateManager))
            return

        setTableData(undefined)
        setTableColumns(undefined)

        const formValues = props.formStateManager.getFormValues()!
        const dto: DataAnalysisSearchRequestDTO = {
            fieldCodes: formValues.fieldCodes,
            regulationCode: formValues.fieldRegulationCode,
            dateRange: formValues.dateRange,
            locationCodes: formValues.locationCodes,
            samplingCode: formValues.samplingCode,
            forceOutsideRegulation: formValues.forceOutsideRegulation,
        }
        getDataAnalisysRequest.runRequest(DataAnalysisRequests.search(dto))
        setDataAnalysisSearchRequestDTO(dto)
    }

    /**
     * Retorno da requisicao.
     */
    function onGetDataAnalysisRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getDataAnalisysRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        const result = getDataAnalisysRequest.responseData!.list

        const tableStructure = TableDataAnalysisUtils.mountTableStructure(result, onClickToEditData)
        setTableColumns(tableStructure.columns)
        setTableData(tableStructure.data)
    }

    /**
     */
    function onClickToEditData (data: FieldValueResponseDTO): void {
        setIsModalMeasurementDataVisible(true)
        setMeasurementDataToEdit(data)
    }

    if (!tableData || !tableColumns || !dataAnalysisSearchRequestDTO)
        return <></>

    return (
        <>
            <BulkActionBtnsContainerSCP>
                <ButtonExportFromApiCP
                    requestConfig={() => DataAnalysisRequests.searchExport(dataAnalysisSearchRequestDTO)}
                    fileName={'analise_dados'}
                />
            </BulkActionBtnsContainerSCP>
            <TableWrapperSCP>
                <TableCP
                    loading={getDataAnalisysRequest.isAwaiting}
                    data={tableData}
                    columns={tableColumns}
                    bordered={true}
                />
            </TableWrapperSCP>

            {
                !!measurementDataToEdit &&
                <ModalDataMeasurementCP
                    visible={isModalMeasurementDataVisible}
                    fieldValue={measurementDataToEdit}
                    onCancel={() => setIsModalMeasurementDataVisible(false)}
                    onSave={() => {
                        doSearch()
                        setIsModalMeasurementDataVisible(false)
                    }}
                />
            }
        </>
    )
}

const TableWrapperSCP = styled.div`
  
  >div {
    height: calc(100vh - 300px);
    overflow-y: scroll;
  }
  
  .ant-table-wrapper {
    padding: 20px;
  }
  
  .aqt-class-col-0 {
    background: #adadad !important;
  }
  
  .aqt-error-value {
    //color: red;
    //font-weight: bold;
  }

  thead {
    background: red !important;
    position: sticky;
    top: 0;
    z-index: 2;
  }
  
  .fixedColumn {
    background: #fff !important;
    position: sticky;
    z-index: 1;
  }
  .fixedColumn.col1 {
    left: 0;
  }
  .fixedColumn.col2 {
    left: 150px;
  }
  .fixedColumn.col3 {
    left: 300px;
  }
`

const BulkActionBtnsContainerSCP = styled.div`
	display: flex;
	margin-left: 20px;
`
