import { AppStateUtils } from 'app/redux/AppStateUtils'
import { PermissionEnum } from 'submodules/nerit-aquatec-sdk/services/auth/authenticate/enums/PermissionEnum'

/**
 */
export class PermissionUtils {

	/**
	 * Retorna se usuario eh do tipo desejado
	 */
	static canAccess(permission: PermissionEnum): boolean {

        return  AppStateUtils.getLoggedUser()?.roles?.includes(permission) ?? false
	}
}
