import React, { useEffect, useState } from 'react'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { BasicModalCPProps, ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { FieldFormModel } from 'modules/fields/components/modals/modal-field/inner/FieldFormModel'
import { SelectFieldTypeCP } from 'modules/fields/components/selects/select-field-type/SelectFieldTypeCP'
import { FieldTypeEnum } from 'submodules/nerit-aquatec-sdk/services/fields/types/FieldTypeEnum'
import { SelectFieldElementTypeCP } from 'modules/fields/components/selects/select-field-element-type/SelectFieldElementTypeCP'
import { FieldResponseDTO } from 'submodules/nerit-aquatec-sdk/services/fields/dtos/responses/FieldResponseDTO'
import { FieldSaveRequestDTO } from 'submodules/nerit-aquatec-sdk/services/fields/dtos/requests/FieldSaveRequestDTO'
import { FieldRequests } from 'submodules/nerit-aquatec-sdk/services/fields/FieldRequests'

interface ICPProps extends BasicModalCPProps {
    field?: FieldResponseDTO
}

/**
 */
export function ModalFieldCP(props: ICPProps): JSX.Element {

    const [formModel, setFormModel] = useState<FieldFormModel>(new FieldFormModel())
    const formStateManager = useFormStateManager(formModel)

    const saveRequest = useRequest<void>('none')
    useEffect(onSaveRequestChange, [saveRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     */
    function init(): void {

        setFormModel(new FieldFormModel())
        if (!props.visible || !props.field)
            return

        setFormModel(new FieldFormModel({
            name: props.field.name,
            type: props.field.type,
            unit: props.field.unity,
            elementType: props.field.elementType,
            maxPermitted: props.field.maxValid,
            minPermitted: props.field.minValid,
        }))
    }

    async function save(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!
        const dto: FieldSaveRequestDTO = {
            name: formValues.name,
            type: formValues.type,
            elementType: formValues.elementType,
            unit: formValues.unit,
            minPermitted: formValues.minPermitted,
            maxPermitted: formValues.maxPermitted,
        }

        if (!!props.field)
            saveRequest.runRequest(FieldRequests.update(props.field.code, dto))
        else
            saveRequest.runRequest(FieldRequests.create(dto))
    }

    /**
     */
    function onSaveRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE, true))
            return

        props.onSave()
    }

    return (
        <ModalCP
            visible={props.visible}
            actionLoading={saveRequest.isAwaiting}
            onOk={save}
            onCancel={props.onCancel}
            title={'Novo Parâmetro'}
            width={500}
        >
            <InputCP
                label={'Nome'}
                required={true}
                formStateManager={formStateManager}
                fieldName={'name'}
            />

            <RowCP>
                <ColumnCP size={12}>
                    <SelectFieldTypeCP
                        isRequired={true}
                        formStateManager={formStateManager}
                        fieldName={'type'}
                    />
                </ColumnCP>
                <ColumnCP size={12}>
                    <InputCP
                        label={'Unidade de Medida'}
                        formStateManager={formStateManager}
                        fieldName={'unit'}
                    />
                </ColumnCP>
            </RowCP>

            <SelectFieldElementTypeCP
                isRequired={true}
                formStateManager={formStateManager}
                fieldName={'elementType'}
            />

            {
                formStateManager.getFieldValue('type') === FieldTypeEnum.NUMERIC &&
                <RowCP>
                    <ColumnCP size={12}>
                        <InputCP
                            label={'Mínimo Permitido'}
                            formStateManager={formStateManager}
                            fieldName={'minPermitted'}
                        />
                    </ColumnCP>
                    <ColumnCP size={12}>
                        <InputCP
                            label={'Máximo Permitido'}
                            formStateManager={formStateManager}
                            fieldName={'maxPermitted'}
                        />
                    </ColumnCP>
                </RowCP>
            }
        </ModalCP>
    )
}
