import React, { useEffect, useState } from 'react'
import { BasicModalCPProps, ModalCP } from 'submodules/nerit-framework-ui/common/components/modal/ModalCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { DataMeasurementFormModel } from 'modules/locations/components/modals/modal-data-measurement/inner/DataMeasurementFormModel'
import { FieldValueResponseDTO } from 'submodules/nerit-aquatec-sdk/services/data-analysis/dtos/response/FieldValueResponseDTO'
import { SelectLocationCP } from 'common/components/form/SelectLocationCP'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { FieldValueSaveRequestDTO } from 'submodules/nerit-aquatec-sdk/services/data-analysis/dtos/request/FieldValueSaveRequestDTO'
import { DataAnalysisRequests } from 'submodules/nerit-aquatec-sdk/services/data-analysis/DataAnalysisRequests'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ButtonDeleteRecordCP } from 'submodules/nerit-framework-ui/common/components/table/columns/ButtonDeleteRecordCP'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'

interface ICPProps extends BasicModalCPProps {
    fieldValue: FieldValueResponseDTO
}

/**
 */
export function ModalDataMeasurementCP(props: ICPProps): JSX.Element {

    const [formModel, setFormModel] = useState<DataMeasurementFormModel>(new DataMeasurementFormModel())
    const formStateManager = useFormStateManager(formModel)

    const saveRequest = useRequest<void>('none')
    useEffect(onSaveRequestChange, [saveRequest.isAwaiting])

    useEffect(init, [props.visible])

    /**
     */
    function init(): void {

        setFormModel(new DataMeasurementFormModel())
        if (!props.visible || !props.fieldValue)
            return

        setFormModel(new DataMeasurementFormModel({
            date: DateUtils.toDate(props.fieldValue.creationDate, DateFormatEnum.US_WITHOUT_TIME),
            compiledValue: props.fieldValue.compiledValue,
            locationCode: props.fieldValue.locationCode,
            originalValue: props.fieldValue.originalValue,
        }))
    }

    async function save(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!
        const dto: FieldValueSaveRequestDTO = {
            creationDate: DateUtils.formatDate(formValues.date, DateFormatEnum.US_WITHOUT_TIME),
            originalValue: formValues.originalValue
        }

        saveRequest.runRequest(DataAnalysisRequests.updateMeasurement(props.fieldValue.code, dto))
    }

    /**
     */
    function onSaveRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(saveRequest, NotificationHelper.DEFAULT_ERROR_SAVE_MESSAGE, NotificationHelper.DEFAULT_SUCCESS_SAVE_MESSAGE, true))
            return

        props.onSave()
    }

    return (
        <ModalCP
            visible={props.visible}
            actionLoading={saveRequest.isAwaiting}
            onOk={save}
            onCancel={props.onCancel}
            title={'Valor de Medição'}
            width={300}
            footer={
                <FlexCP justifyContent={'space-between'}>
                    <ButtonDeleteRecordCP
                        requestConfigTP={() => DataAnalysisRequests.deleteMeasurement(props.fieldValue.code)}
                        onDelete={props.onSave}
                        appearance={{
                            label: 'Remover',
                            size: 'default',
                            hideIcon: true,
                        }}
                    />
                    <ButtonCP
                        type={'primary'}
                        onClick={save}
                        loading={saveRequest.isAwaiting}
                    >
                        Salvar
                    </ButtonCP>
                </FlexCP>
            }
        >

            <SelectLocationCP
                formStateManager={formStateManager}
                fieldName={'locationCode'}
                isRequired={true}
                disable={true}
            />

            <InputCP
                type={'date'}
                formStateManager={formStateManager}
                fieldName={'date'}
                required={true}
            />

            <InputCP
                formStateManager={formStateManager}
                fieldName={'originalValue'}
                required={true}
            />

            <InputCP
                formStateManager={formStateManager}
                fieldName={'compiledValue'}
                required={true}
                disabled={true}
            />

        </ModalCP>
    )
}
