import React, { useEffect, useState } from 'react'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import styled from 'styled-components'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { FilterMapCP } from 'modules/map/components/filters-map/FilterMapCP'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { MapFiltersFormModel } from 'modules/map/components/filters-map/inner/MapFiltersFormModel'
import { MapRequests } from 'submodules/nerit-aquatec-sdk/services/map/MapRequests'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { WelcomeContentCP } from 'submodules/nerit-framework-ui/common/components/welcome-content/WelcomeContentCP'
import { IconICP } from 'submodules/nerit-framework-ui/common/components/icon/inner/IconICP'
import { ListResponseDTO } from 'submodules/nerit-framework-utils/sdk-utils/dtos/response/ListResponseDTO'
import { SearchMarkersRequestDTO } from 'submodules/nerit-aquatec-sdk/services/map/dtos/requests/SearchMarkersRequestDTO'
import { SCREEN_FILTER_URL_PARAM } from 'submodules/nerit-framework-ui/common/screens/inner/ScreenTypes'
import { useLocation } from 'react-router'
import { MarkerResponseDTO } from 'submodules/nerit-aquatec-sdk/services/map/dtos/responses/MarkerResponseDTO'
import { SwitchCP } from 'submodules/nerit-framework-ui/common/components/form-fields/switch/SwitchCP'
import { GoogleMapCP } from 'modules/map/components/google-map/GoogleMapCP'
import { MapPinTP } from 'modules/map/components/google-map/inner/MapPinTP'
import { ThemeProjectCommon } from 'config/theme/project/white-labels/ThemeProjectCommon'
import { ModalMapPinCP } from 'modules/map/components/modal-map-pin/ModalMapPinCP'
import { LocationResponseDTO } from 'submodules/nerit-aquatec-sdk/services/location/dtos/responses/LocationResponseDTO'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'

/**
 */
export function ScreenMap(): JSX.Element {

    const routeLocation = useLocation()

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
    const [selectedPin, setSelectedPin] = useState<LocationResponseDTO>()

    const [formModal, setFormModel] = useState<MapFiltersFormModel>(new MapFiltersFormModel())
    const formStateManager = useFormStateManager(formModal)

    const [mapPins, setMapPins] = useState<MapPinTP[]>()
    const [hiddenMapPinKeys, setHiddenMapPinKeys] = useState<number[]>([])

    const getMapMarkersRequest = useRequest<ListResponseDTO<MarkerResponseDTO>>()
    useEffect(onGetMapMarkersChange, [getMapMarkersRequest.isAwaiting])

    useEffect(init, [])

    /**
     */
    function init(): void {

        const queryFiltersStr = new URLSearchParams(routeLocation.search).get(SCREEN_FILTER_URL_PARAM)
        let queryFilters: SearchMarkersRequestDTO | undefined
        if (!!queryFiltersStr) {
            queryFilters = JSON.parse(queryFiltersStr)
            setFormModel(new MapFiltersFormModel({
                fieldCode: queryFilters?.fieldCode,
                fieldRegulationCode: queryFilters?.regulationCode,
                dateRange: queryFilters?.dateRange,
                samplingCode: queryFilters?.samplingCode,
            }))
        }
    }

    /**
     */
    async function searchMapMarkers(): Promise<void> {

        if (!await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        const formValues = formStateManager.getFormValues()!

        const dto: SearchMarkersRequestDTO = {
            fieldCode: formValues.fieldCode,
            dateRange: formValues.dateRange,
            regulationCode: formValues.fieldRegulationCode,
            samplingCode: formValues.samplingCode,
            tolerancePercentage: formValues.tolerance
        }
        getMapMarkersRequest.runRequest(MapRequests.getMarkers(dto))
    }

    /**
     */
    function onGetMapMarkersChange(): void {

        if (!RequestUtils.isValidRequestReturn(getMapMarkersRequest, 'Pesquisa inválida'))
            return

        const result = getMapMarkersRequest.responseData!.list
        const mapValues: MapPinTP[] = []
        result.forEach((marker) => {

            const latNumber = +marker.location.latitude
            const lngNumber = +marker.location.longitude

            if (Number.isNaN(latNumber) || Number.isNaN(lngNumber)) {
                NotificationHelper.warning('Ponto com coordenada inválida', `O ponto ${marker.location.name} possui coordenadas inválidas (lat: ${marker.location.latitude}, lng: ${marker.location.longitude})`)
                return
            }

            mapValues.push({
                id: marker.location.code,
                position: {
                    lat: latNumber,
                    lng: lngNumber,
                },
                title: marker.location.name,
                icon: marker.isOutsidePermittedLimits === undefined ? ThemeProjectCommon.mapMarkers.yellow : (marker.isOutsidePermittedLimits ? ThemeProjectCommon.mapMarkers.red : ThemeProjectCommon.mapMarkers.green),
                onClick: () => {
                    setIsModalVisible(true)
                    setSelectedPin(marker.location)
                }
            })

        })
        setMapPins(mapValues)

    }

    /**
     */
    function onHidePin(id: number): void {

        if (!mapPins)
            return

        const newHiddenPinKeys = [...hiddenMapPinKeys, id]
        setHiddenMapPinKeys(newHiddenPinKeys)

        const newPins = mapPins.filter((pin) => !newHiddenPinKeys.includes(pin.id))
        setMapPins([...newPins])

        setIsModalVisible(false)
        setSelectedPin(undefined)
    }

    return (
        <LayoutCP
            overflowVertical={true}
            content={
                <>
                    <HeaderCP title={'Mapa'}>
                        <SwitchCP
                            formStateManager={formStateManager}
                            fieldName={'showTitle'}
                            label={'Mostrar título no mapa'}
                        />
                    </HeaderCP>
                    <FilterMapCP
                        formStateManager={formStateManager}
                        onFilter={searchMapMarkers}
                        isSearching={getMapMarkersRequest.isAwaiting}
                    />

                    {
                        !!mapPins
                            ?
                            <WrapperSCP style={{ height: '100%' }}>
                                <GoogleMapCP
                                    markers={mapPins}
                                    title={formStateManager.getFieldValue('showTitle') ? formStateManager.getFieldValue('fieldName') : undefined}
                                />
                            </WrapperSCP>
                            :
                            <WelcomeContentCP
                                message={'Selecione os filtros e clique em pesquisar'}
                                icon={<IconICP iconName={'filter'} size={42}/>}
                            />
                    }

                    {
                        !!selectedPin &&
                        <ModalMapPinCP
                            onCancel={() => setIsModalVisible(false)}
                            location={selectedPin}
                            formStateManager={formStateManager}
                            onHide={() => onHidePin(selectedPin.code)}
                            visible={isModalVisible}
                        />
                    }
                </>
            }
        />
    )
}

const WrapperSCP = styled.div`
  height: 80%;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
`
