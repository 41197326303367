import React from 'react'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { PermissionEnum, PermissionLabelEnum } from 'submodules/nerit-aquatec-sdk/services/auth/authenticate/enums/PermissionEnum'

interface ICPProps {
    value?: PermissionEnum
    isRequired?: boolean
    onChange?: (val: PermissionEnum) => void
    width?: number
    fieldName?: string
    formStateManager?: IFormStateManager<any>
}

export function SelectPermissionCP(props: ICPProps): JSX.Element {

    return (
        <SelectCP
            label={'Permissão'}
            required={!!props.isRequired}
            options={Object.values(PermissionEnum).map((val) => ({
                value: val,
                label: PermissionLabelEnum[val]
            }))}
            onChange={props.onChange}
            width={props.width}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
        />
    )
}

