
export enum PermissionEnum {
	ROLE_ADMIN = 'ROLE_ADMIN',
	ROLE_USER = 'ROLE_USER',
}

export enum PermissionLabelEnum {
	ROLE_ADMIN = 'Administrador',
	ROLE_USER = 'Usuário',
}
