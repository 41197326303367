import React, { useState } from 'react'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { TableUsersCP } from 'modules/users/components/table-users/TableUsersCP'
import { ModalUserCP } from 'modules/users/components/modal-user/ModalUserCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { UserRequests } from 'submodules/nerit-aquatec-sdk/services/users/UserRequests'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { PermissionEnum } from 'submodules/nerit-aquatec-sdk/services/auth/authenticate/enums/PermissionEnum'

export function ScreenUsers(): JSX.Element {

    const [shouldLoadData, setShouldLoadData] = useState<number>(TableUtils.getReloadNumber())
    const [createUserVisible, setCreateUserVisible] = useState<boolean>(false) //eslint-disable-line

    const postRequest = useRequest<any>()

    function onResetPassword(code: number): void {

        postRequest.runRequest(UserRequests.resetPassword({code}))
        setTimeout(() => setShouldLoadData(TableUtils.getReloadNumber()), 2000)
    }

    function onDeactivate(code: number): void {

        postRequest.runRequest(UserRequests.removeUser({code}))
        setTimeout(() => setShouldLoadData(TableUtils.getReloadNumber()), 2000)
    }

    return (
        <LayoutCP
            header={
                <HeaderCP title={'Usuários'}>
                    <AccessControlCP permission={PermissionEnum.ROLE_ADMIN}>
                        <ButtonCP
                            onClick={() => setCreateUserVisible(true)}
                            icon={'plus'}
                        >
                            Novo usuário
                        </ButtonCP>
                    </AccessControlCP>
                </HeaderCP>
            }
            overflowVertical={true}
            content={
                <>
                    <TableUsersCP
                        shouldLoadData={shouldLoadData}
                        onResetPassword={onResetPassword}
                        onDeactivate={onDeactivate}
                    />

                    <ModalUserCP
                        visible={createUserVisible}
                        onCancel={() => setCreateUserVisible(false)}
                        onSave={() => {
                            setCreateUserVisible(false)
                            setShouldLoadData(TableUtils.getReloadNumber())
                        }}
                    />
                </>
            }
        />
    )
}
