import React from 'react'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { WrapperFiltersCP } from 'submodules/nerit-framework-ui/features/common/components/filters/wrapper-filters/WrapperFiltersCP'
import { FieldFiltersFormModel } from 'modules/fields/components/filters/filters-fields/inner/FieldFiltersFormModel'
import { InputCP } from 'submodules/nerit-framework-ui/common/components/form-fields/input/InputCP'

interface ICPProps {
    formStateManager: IFormStateManager<FieldFiltersFormModel>
    onFilter: () => void
    isLoading?: boolean
}

/**
 */
export function FiltersFieldsCP(props: ICPProps): JSX.Element {

    return (
        <WrapperFiltersCP
            filters={
                <RowCP>
                    <ColumnCP size={24}>
                        <InputCP
                            formStateManager={props.formStateManager}
                            fieldName={'searchString'}
                            label={'Pesquise pelo parâmetro'}
                            allowClear={true}
                        />
                    </ColumnCP>
                </RowCP>
            }
            filterButton={
                <ButtonCP
                    icon={'search'}
                    type={'primary'}
                    onClick={props.onFilter}
                    loading={props.isLoading}
                />
            }
        />
    )
}
