import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsRequired } from 'submodules/nerit-framework-utils/validators'

export class DataMeasurementFormModel extends FormModel {

    @IsRequired()
    date: Date

    @IsRequired()
    locationCode: number

    @IsRequired()
    originalValue: string

    @IsRequired()
    compiledValue: number

    constructor(initialData?: Partial<DataMeasurementFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
