import React from 'react'
import { ColumnProps } from 'antd/es/table'
import { DateUtils } from 'submodules/nerit-framework-utils/utils/date/DateUtils'
import { DateFormatEnum } from 'submodules/nerit-framework-utils/utils/enums/DateFormatEnum'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { DataImportResponseDTO } from 'submodules/nerit-aquatec-sdk/services/data-import/dtos/response/DataImportResponseDTO'
import { TagCP } from 'submodules/nerit-framework-ui/common/components/tag/TagCP'
import { ImportationLogStatusEnum, ImportationLogStatusLabelEnum } from 'submodules/nerit-aquatec-sdk/services/data-import/enums/ImportationLogStatusEnum'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { ButtonSamplingDoImportCP } from 'modules/samplings/components/button-sampling-do-import/ButtonSamplingDoImportCP'
import { ButtonDeleteRecordCP } from 'submodules/nerit-framework-ui/common/components/table/columns/ButtonDeleteRecordCP'
import { DataImportRequests } from 'submodules/nerit-aquatec-sdk/services/data-import/DataImportRequests'
import { NeritFrameworkRoutingHelper } from 'submodules/nerit-framework-ui/common/router/utils/NeritFrameworkRoutingHelper'
import { PermissionEnum } from 'submodules/nerit-aquatec-sdk/services/auth/authenticate/enums/PermissionEnum'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'

export const SamplingImportTableUtils = {

    getTableColumns(
        onShowLogs: (record: DataImportResponseDTO) => void,
        onUpdate: () => void
    ): Array<ColumnProps<DataImportResponseDTO>> {

        return [
            {
                title: 'Data',
                render: (text, record) => DateUtils.formatDate(record.date, DateFormatEnum.BR_WITH_TIME_H_M)
            },
            {
                title: 'Modelo',
                render: (text, record) => record.importationSource
            },
            {
                title: 'Identificação',
                render: (text, record) => record.identification
            },
            {
                title: 'Modo',
                render: (text, record) => <TagCP content={record.validationMode ? 'Validação' : 'Importação'} color={record.validationMode ? 'yellow' : 'green'}/>
            },
            {
                title: 'Sucesso',
                render: (text, record) => record.totalSuccess
            },
            {
                title: 'Erro',
                render: (text, record) => record.totalError
            },
            {
                title: 'Total',
                render: (text, record) => record.totalSuccess + record.totalError
            },
            {
                render: (text, record) => ImportationLogStatusLabelEnum[record.status]
            },
            {
                render: (text, record) => (
                    <ButtonGroupCP mode={'separeted'}>
                        {
                            !!record.log &&
                            <ButtonCP
                                tooltip={'Ver log de erros'}
                                icon={'file-search'}
                                onClick={() => onShowLogs(record)}
                                size={'small'}
                                type={'ghost'}
                            />
                        }
                        {
                            !!record.sourceFileUrl &&
                            <ButtonCP
                                tooltip={'Baixar planilha original'}
                                icon={'download'}
                                onClick={() => NeritFrameworkRoutingHelper.openInNewTab(record.sourceFileUrl)}
                                size={'small'}
                                type={'ghost'}
                            />
                        }
                        <AccessControlCP permission={PermissionEnum.ROLE_ADMIN}>
                            <ButtonSamplingDoImportCP
                                importCode={record.importationLogCode}
                                importStatus={record.status}
                            />
                            {
                                record.status === ImportationLogStatusEnum.CONCLUDED &&
                                <ButtonDeleteRecordCP
                                    requestConfigTP={() => DataImportRequests.delete(record.importationLogCode)}
                                    onDelete={onUpdate}
                                />
                            }
                        </AccessControlCP>
                    </ButtonGroupCP>
                )
            },
        ]
    }

}
