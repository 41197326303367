import React from 'react'
import { SelectCP } from 'submodules/nerit-framework-ui/common/components/form-fields/select/SelectCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FieldTypeEnum, FieldTypeLabelEnum } from 'submodules/nerit-aquatec-sdk/services/fields/types/FieldTypeEnum'

interface ICPProps {
    fieldType?: FieldTypeEnum
    isRequired?: boolean
    onChange?: (ntr: FieldTypeEnum) => void
    width?: number
    fieldName?: string
    formStateManager?: IFormStateManager<any>
}

export function SelectFieldTypeCP(props: ICPProps): JSX.Element {

    return (
        <SelectCP
            label={'Tipo'}
            required={!!props.isRequired}
            options={Object.values(FieldTypeEnum).map((val) => ({
                value: val,
                label: FieldTypeLabelEnum[val]
            }))}
            onChange={props.onChange}
            width={props.width}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
        />
    )
}

