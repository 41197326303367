import { SelectFieldCP } from 'modules/fields/components/select-field/SelectFieldCP'
import { SelectLocationCP } from 'common/components/form/SelectLocationCP'
import React from 'react'
import styled from 'styled-components'
import { ButtonCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonCP'
import { DateRangePickerCP } from 'submodules/nerit-framework-ui/common/components/form-fields/date-range-picker/DateRangePickerCP'
import { ColumnCP } from 'submodules/nerit-framework-ui/common/components/grid/column/ColumnCP'
import { RowCP } from 'submodules/nerit-framework-ui/common/components/grid/row/RowCP'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FlexCP } from 'submodules/nerit-framework-ui/common/components/flex/FlexCP'
import { FlexOneICP } from 'submodules/nerit-framework-ui/common/components/flex/inner/FlexOneICP'
import { GeographicChartFiltersFormModel } from 'modules/reports/components/filters/filter-geographic-chart/inner/GeographicChartFiltersFormModel'
import { SelectFieldRegulationCP } from 'common/components/form/SelectFieldRegulationCP'

interface ICPProps {
    onFilter: () => void
    formStateManager: IFormStateManager<GeographicChartFiltersFormModel>
    loading?: boolean
}

export function FilterGeographicChartCP(props: ICPProps): JSX.Element {

    return (
        <ContentSCP>
            <FlexCP justifyContent={'space-between'} alignItems={'flex-end'}>
                <FlexOneICP marginRight={10}>
                    <RowCP>
                        <ColumnCP size={6}>
                            <SelectLocationCP
                                formStateManager={props.formStateManager}
                                fieldName={'locationCodes'}
                                isRequired={true}
                                isMany={true}
                            />
                        </ColumnCP>
                        <ColumnCP size={6}>
                            <SelectFieldCP
                                formStateManager={props.formStateManager}
                                fieldName={'fieldCode'}
                                isRequired={true}
                                auxFieldNameSelectedOption={'fieldName'}
                            />
                        </ColumnCP>
                        <ColumnCP size={6}>
                            <SelectFieldRegulationCP
                                formStateManager={props.formStateManager}
                                fieldName={'fieldRegulationCode'}
                                auxFieldNameSelectedOption={'regulationName'}
                            />
                        </ColumnCP>
                        <ColumnCP size={6}>
                            <DateRangePickerCP
                                showBorder={true}
                                marginTop={23}
                                fastFilter={'all'}
                                fieldName={'dateRange'}
                                onChange={(dateRange) => props.formStateManager.changeFieldValue('dateRange', dateRange)}
                                value={props.formStateManager.getFieldValue('dateRange')}
                            />
                        </ColumnCP>
                    </RowCP>
                </FlexOneICP>
                <ButtonCP
                    marginBottom={10}
                    icon={'search'}
                    type={'primary'}
                    onClick={props.onFilter}
                    loading={props.loading}
                />
            </FlexCP>
        </ContentSCP>
    )
}

const ContentSCP = styled.div`
  width: 100%;
  background: #fff;
  padding: 1rem 2rem;
`
