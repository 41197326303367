import { FormModel } from 'submodules/nerit-framework-ui/common/form-state-manager/types/FormModel'
import { IsOptional } from 'class-validator'
import { IFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/types/IFormStateManager'
import { FieldSearchRequestDTO } from 'submodules/nerit-aquatec-sdk/services/fields/dtos/requests/FieldSearchRequestDTO'

export class LocationsFiltersFormModel extends FormModel {

    @IsOptional()
    searchString?: number

    constructor(initialData?: Partial<LocationsFiltersFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }

    /**
     */
    static formatDto(formStateManager: IFormStateManager<LocationsFiltersFormModel>): FieldSearchRequestDTO {

        if (!formStateManager.getFormValues())
            return {}

        const formValues = formStateManager.getFormValues()!
        return {
            searchString: formValues.searchString
        }
    }

}
