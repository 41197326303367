import React, { useEffect, useState } from 'react'
import { LayoutCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/layout/LayoutCP'
import { HeaderCP } from 'submodules/nerit-framework-ui/common/components/screen/layout/header/HeaderCP'
import { useRequest } from 'submodules/nerit-framework-ui/common/request-manager/use-request/UseRequest'
import { ChartsRequests } from 'submodules/nerit-aquatec-sdk/services/charts/ChartsRequests'
import { RequestUtils } from 'submodules/nerit-framework-utils/sdk-utils/request-manager/RequestUtils'
import { useLocation } from 'react-router'
import { useFormStateManager } from 'submodules/nerit-framework-ui/common/form-state-manager/UseFormStateManager'
import { FormStateManagerUtils } from 'submodules/nerit-framework-ui/common/form-state-manager/FormStateManagerUtils'
import { NotificationHelper } from 'submodules/nerit-framework-ui/common/components/notification/inner/NotificationHelper'
import { SCREEN_FILTER_URL_PARAM } from 'submodules/nerit-framework-ui/common/screens/inner/ScreenTypes'
import { SystemUtils } from 'submodules/nerit-framework-utils/utils/SystemUtils'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { WelcomeContentWithFilterWrapperCP } from 'app/components/welcome-content-with-filter-wrapper/WelcomeContentWithFilterWrapperCP'
import { FilterStatisticChartCP } from 'modules/reports/components/filters/filter-statistic-chart/FilterStatisticChartCP'
import { StatisticChartRequestDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/requests/StatisticChartRequestDTO'
import { StatisticChartFiltersFormModel } from 'modules/reports/components/filters/filter-statistic-chart/inner/StatisticChartFiltersFormModel'
import { StatisticChartResponseDTO } from 'submodules/nerit-aquatec-sdk/services/charts/dtos/responses/StatisticChartResponseDTO'
import { TableGenericChartCP } from 'modules/reports/components/tables/table-generic-chart/TableGenericChartCP'
import { ChartStatisticCP } from 'modules/reports/components/charts/chart-statistic/ChartStatisticCP'

/**
 */
export function ScreenContentChartStatistic(): JSX.Element {

    const routeLocation = useLocation()

    const [formModel, setFormModel] = useState<StatisticChartFiltersFormModel>(new StatisticChartFiltersFormModel())
    const formStateManager = useFormStateManager(formModel)

    const [reload, setReload] = useState<number>()
    const [loadedData, setLoadedData] = useState<StatisticChartResponseDTO>()

    const [statisticChartRequestDTO, setStatisticChartRequestDTO] = useState<StatisticChartRequestDTO>()
    const getStatisticChartRequest = useRequest<StatisticChartResponseDTO>()
    useEffect(onFetStatisticChartRequestChange, [getStatisticChartRequest.isAwaiting])

    useEffect(init, [])
    useEffect(() => {
        filter()
    }, [reload])

    /**
     */
    function init(): void {

        const queryFiltersStr = new URLSearchParams(routeLocation.search).get(SCREEN_FILTER_URL_PARAM)
        let queryFilters: StatisticChartRequestDTO | undefined
        if (!!queryFiltersStr) {
            queryFilters = JSON.parse(queryFiltersStr)
            setFormModel(new StatisticChartFiltersFormModel({
                fieldCode: queryFilters?.fieldCode,
                locationCode: queryFilters?.locationCode,
                dateRange: queryFilters?.dateRange,
                fieldRegulationCode: queryFilters?.regulationCode,
            }))
            // Para garantir que ira resetar os campos do filtro
            SystemUtils.sleep(100).then(() => {
                setReload(TableUtils.getReloadNumber())
            })
        }
    }

    /**
     */
    async function filter(): Promise<void> {

        if (!reload || !await FormStateManagerUtils.validateRequiredFields(formStateManager))
            return

        setLoadedData(undefined)
        const formValues = formStateManager.getFormValues()!

        const filters: StatisticChartRequestDTO = {
            fieldCode: formValues.fieldCode,
            regulationCode: formValues.fieldRegulationCode,
            dateRange: formValues.dateRange,
            locationCode: formValues.locationCode,
        }
        getStatisticChartRequest.runRequest(ChartsRequests.getStatisticChart(filters))
        setStatisticChartRequestDTO(filters)
    }

    /**
     */
    function onFetStatisticChartRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getStatisticChartRequest, NotificationHelper.DEFAULT_ERROR_GET_MESSAGE))
            return

        setLoadedData(getStatisticChartRequest.responseData!)
    }

    return (
        <LayoutCP
            overflowVertical={true}
            header={
                <HeaderCP title={'Estatística'}/>
            }
            content={
                <>
                    <FilterStatisticChartCP
                        formStateManager={formStateManager}
                        onFilter={() => setReload(TableUtils.getReloadNumber())}
                        loading={getStatisticChartRequest.isAwaiting}
                    />

                    <WelcomeContentWithFilterWrapperCP showWelcome={!loadedData}>
                        <>
                            {
                                !!loadedData && !!statisticChartRequestDTO &&
                                <>
                                    <ChartStatisticCP
                                        data={loadedData!}
                                        filters={{
                                            locationName: formStateManager.getFieldValue('locationName'),
                                            regulationName: formStateManager.getFieldValue('regulationName')
                                        }}
                                    />
                                    <TableGenericChartCP
                                        data={{
                                            regulationLimit: loadedData!.regulationLimit,
                                            series: [loadedData!.fieldSerie]
                                        }}
                                        export={{
                                            exportRequestConfig: () => ChartsRequests.getStatisticChartExport(statisticChartRequestDTO),
                                            fileName: 'estatistica',
                                        }}
                                    />
                                </>
                            }
                        </>
                    </WelcomeContentWithFilterWrapperCP>
                </>
            }
        />
    )
}
