import React, { useEffect, useState } from 'react'
import { LocationRequests } from 'submodules/nerit-aquatec-sdk/services/location/LocationRequests'
import { TableFromApiCP } from 'submodules/nerit-framework-ui/common/components/table/TableFromApiCP'
import { ButtonGroupCP } from 'submodules/nerit-framework-ui/common/components/button/ButtonGroupCP'
import { LocationResponseDTO } from 'submodules/nerit-aquatec-sdk/services/location/dtos/responses/LocationResponseDTO'
import { ButtonModalLocationCP } from 'modules/locations/components/buttons/button-modal-location/ButtonModalLocationCP'
import { TableUtils } from 'submodules/nerit-framework-ui/common/components/table/utils/TableUtils'
import { ButtonDeleteRecordCP } from 'submodules/nerit-framework-ui/common/components/table/columns/ButtonDeleteRecordCP'
import { TextCP } from 'submodules/nerit-framework-ui/common/components/text/TextCP'
import { PermissionEnum } from 'submodules/nerit-aquatec-sdk/services/auth/authenticate/enums/PermissionEnum'
import { AccessControlCP } from 'app/components/access-control/AccessControlCP'
import { LocationSearchRequestDTO } from 'submodules/nerit-aquatec-sdk/services/location/dtos/requests/LocationSearchRequestDTO'

interface ICPProps {
    shouldLoadData: number
    filters: LocationSearchRequestDTO
}

export function TableLocationsCP(props: ICPProps): JSX.Element {

    const [reloadData, setReloadData] = useState<number>(props.shouldLoadData)
    useEffect(() => setReloadData(props.shouldLoadData), [props.shouldLoadData])

    return (
        <TableFromApiCP<LocationResponseDTO>
            apiConfig={{
                hasPagination: true,
                pageSize: 20,
                requestConfigTP: (filtersDto) => LocationRequests.getList({
                    ...filtersDto, ...props.filters,
                })
            }}
            appearance={{
                showReloadButton: true,
                wrappedOnCard: true,
                showTotalOnHeader: true
            }}
            shouldLoadData={reloadData}
            columns={[
                {
                    title: '#',
                    render: (text, record) => record.code
                },
                {
                    title: 'Nome',
                    render: (text, record) => <TextCP text={record.name} size={'small'} copyable={true}/>
                },
                {
                    title: 'Descrição',
                    render: (text, record) => record.description
                },
                {
                    title: 'Latitude',
                    render: (text, record) => <TextCP text={record.latitude} size={'small'} copyable={true} shouldRender={!!record.latitude}/>
                },
                {
                    title: 'Longitude',
                    render: (text, record) => <TextCP text={record.longitude} size={'small'} copyable={true} shouldRender={!!record.longitude}/>
                },
                {
                    title: 'Altitude',
                    render: (text, record) => <TextCP text={record.altitude} size={'small'} copyable={true} shouldRender={!!record.altitude}/>
                },
                {
                    render: (text, record) => (
                        <AccessControlCP permission={PermissionEnum.ROLE_ADMIN}>
                            <ButtonGroupCP mode={'separeted'}>
                                <ButtonModalLocationCP
                                    location={record}
                                    onSave={() => setReloadData(TableUtils.getReloadNumber())}
                                />
                                <ButtonDeleteRecordCP
                                    requestConfigTP={() => LocationRequests.delete(record.code)}
                                    onDelete={() => setReloadData(TableUtils.getReloadNumber())}
                                />
                            </ButtonGroupCP>
                        </AccessControlCP>
                    )
                }
            ]}
        />
    )
}
