export enum FieldElementTypeEnum {
    ORGANIC = 'ORGANIC',
    INORGANIC = 'INORGANIC',
    MICROORGANISM = 'MICROORGANISM',
}

export enum FieldElementTypeLabelEnum {
    ORGANIC = 'Orgânico',
    INORGANIC = 'Inorgânico',
    MICROORGANISM = 'Microrganismo',
}
